@import 'Variables';

.skip-link {
  position: absolute;
  top: 0;
  right: -1000px;
  background: $darkRed;
  color: #fff;
  z-index: 10;
  padding: 0.5em;
  box-shadow: $boxShadowHover;
  transition: $transition;
  &:focus {
    right: 0;
  }
}
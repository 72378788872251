@import 'Variables';

.social {
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 0.3em;
  bottom: 8em;
  a {
    color: $red;
    font-size: 2em;
    transition: $transition;
    &:hover {
      color: $darkRed;
    }
  }
}

@media screen and (min-width: 768px) {
  .social {
    left: 0.5em;
    a {
      font-size: 3em;
    }
  }
}
@import "Variables";

.main-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  padding: 0.5em;
  background-color: $grey;
  color: #000;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  box-shadow: 0 0 20px #000;
}
.main-heading {
  .small-logo {
    height: 50px;
    width: auto;
  }
  .large-logo {
    display: none;
  }
}
.hamburger-icon {
  background: url("../img/hamburger-menu.svg") no-repeat center;
  background-size: cover;
  width: 48px;
  height: 40px;
}
.dialog-nav {
  position: fixed;
  display: block;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  background: transparent;
  animation-duration: 0.3s;
  animation-timing-function: ease;
  transition: transform 0.3s ease;
}
.open {
  transform: translateX(0);
  animation-name: openDialog;
}
.close {
  transform: translateX(5000px);
  animation-name: closeDialog;
}
@keyframes openDialog {
  0% {
    transform: translateX(5000px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes closeDialog {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(5000px);
  }
}
.close-icon {
  cursor: pointer;
  position: fixed;
  width: 2em;
  height: auto;
  top: 0.5em;
  right: 0.5em;
  z-index: 2;
}
.hero-image {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: url("../img/hero-image.jpg") no-repeat;
  background-position: 0 0;
  background-attachment: fixed;
  background-size: cover;
  filter: grayscale(0.5);
}
.hero-text {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  font-size: 1em;
  background: rgba(0, 0, 0, 0.2);
  padding: 1em;
  border: solid 2px #fff;
  line-height: 1.5;
  h2 {
    text-transform: uppercase;
    text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
    letter-spacing: 2;
  }
}
.showLogo {
  animation-name: showLogo;
  animation-duration: 0.5s;
  animation-timing-function: ease;
}
.scroll-icon {
  font-size: 2em;
  color: #fff;
  position: absolute;
  top: 93%;
  left: 50%;
  transform: translateX(-50%);
  animation-name: animate;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes animate {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* RESPONSIVE DESIGN FOR LANDSCAPE MOBILES */

@media screen and (min-width: 480px) and (orientation: landscape) {
  .hero-text {
    min-width: 400px;
    top: 60%;
  }
  .btn-primary {
    width: 255px;
  }
}

@media screen and (min-width: 640px) and (orientation: landscape) {
  .hero-text {
    min-width: 550px;
    font-size: 1.3em;
    top: 61%;
  }
  .btn-primary {
    width: 320px;
  }
}

/* RESPONSIVE DESIGN FOR TABLETS */ 

@media screen and (min-width: 768px) {
  .hero-text {
    top: 55%;
    transform: translate(-50%, -50%);
    font-size: 1.5em;
  }
  .btn-primary {
    width: 360px;
  }
  .main-heading {
    .large-logo {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 200px;
      box-shadow: 0 0 20px #000;
      animation-duration: 0.5s;
      animation-timing-function: ease;
    }
  }
}
@keyframes hideLogo {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes showLogo {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media screen and (min-width: 1280px) {
  .hamburger-icon {
    display: none;
  }
  .main-header {
    height: 4em;
  }
  .main-heading {
    text-align: left;
    font-style: italic;
    color: #fff;
    font-size: 1em;
    .small-logo {
      margin-top: 0.2em;
    }
    h1 {
      display: block;
      margin: 0.5em;
      a {
        color: #fff;
        text-decoration: none;
      }
    }
  }
  .main-nav {
    a {
      color: $darkRed !important;
      font-weight: bold;
      margin-top: -0.8em;
    }
  }
}
@media screen and (min-width: 1440px) {
  .main-heading {
    .large-logo {
      width: 300px;
    }
  }
}
@import 'Variables';

* {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
body {
  width: 100%;
  height: 100%;
  text-align: center;
  overflow-x: hidden;
  font-family: $font;
}
a {
  text-decoration: none;
  cursor: pointer;
}
.btn-primary {
  display: block;
  background-color: $red;
  border: none;
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  text-transform: uppercase;
  min-width: 255px;
  padding: 1em;
  margin: 1em auto;
  box-shadow: $boxShadow;
  transition: $transition;
  i {
    margin-left: 1em;
  }
  &:hover {
    background-color: $darkRed;
    box-shadow: $boxShadowHover;
  }
}
.heading {
  text-align: center;
  font-size: 2em;
  margin: 2em auto;
  color: $darkRed;
  font-style: italic;
  font-weight: bold;
  padding: 0 1em;
  &::after {
    content: "";
    display: block;
    width: 100px;
    height: 3px;
    background-color: $darkRed;
    margin-top: 1em;
    margin-left: 50%;
    transform: translateX(-50%);
  }
}
.container {
  width: 100%;
  padding: 1em;
}
p {
  margin-top: 1em;
  font-size: 1em;
}
@media screen and (min-width: 1280px) {
  .btn-primary {
    margin: 3em auto 0 auto;
  }
}
@import 'Variables';

.services {
  background: $grey url('../img/bulb.svg') no-repeat;
  background-attachment: fixed;
  background-position: 0 0;
  background-size: contain;
  margin-top: -4em;
  padding: 4em 0 2em 0;
}
.services-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: -3em auto 0 auto;
}
.service-card {
  width: 300px;
  display: flex;
  flex-direction: column;
  transition: $transition;
  margin: 1em;
  background: #fff;
  box-shadow: $boxShadowLight;
  i {
    color: $darkRed;
    font-size: 3em;
    margin: 1em auto 0 auto;
  }
  h2 {
    margin: 1em auto;
    &::before {
      content: "";
      display: block;
      width: 100px;
      height: 3px;
      background-color: $darkRed;
      margin: auto auto 1em auto;
    }
  }
}
.service-list {
  list-style-type: none;
  text-align: left;
  display: block;
  margin: 0 auto 3em auto;
  width: 150px;
  li {
    &::before {
      content: "";
      width: 10px;
      height: 10px;
      background-color: $darkRed;
      margin-left: -2em;
      margin-right: 0.7em;
      display: inline-block;
      vertical-align: middle;
      margin-top: -0.2em;
    }
  }
}
@media screen and (min-width: 1024px) {
  .services-container {
    width: 1024px;
    margin: auto;
  }
}
@media screen and (min-width: 1360px) {
  .services-container {
    width: 100%;
    max-width: 1360px;
  }
}
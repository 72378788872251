@import 'Variables';

.main-nav {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  a {
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.5em;
    padding: 0.8em;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 0;
      height: 3px;
      background-color: $red;
      transition: width 0.3s ease;
      left: 50%;
      top: 2em;
    }
    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 3px;
      background-color: $red;
      transition: width 0.3s ease;
      right: 50%;
      top: 2em;
    }
    &:hover {
      &::before {
        width: 37px;
      }
      &::after {
        width: 37px;
      }
    }
  }
}

/* RESPONSIVE DESIGN FOR LANDSCAPE MOBILES */ 

@media screen and (min-width: 480px) and (orientation: landscape) {
  .main-nav {
    a {
      padding: 0.5em;
    }
  }
}
@media screen and (min-width: 1280px) {
  .dialog-nav {
    height: auto;
  }
  .main-nav {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transform: translateX(0);
    background: transparent;
    height: auto;
    width: auto;
    position: fixed;
    right: 1em;
    top: 1.4em;
    a {
      padding: 0.5em 1em;
      &:last-of-type {
        padding-right: 0;
      }
    }
  }
  .close {
    transform: translateX(0);
  }
  .close-icon {
    display: none;
  }
}